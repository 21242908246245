import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [signupData, setSignupData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const token = localStorage.getItem('securimail_token');
    if (!token) {
      setLoading(false);
      return;  // Just return, don't redirect
    }

    try {
      const response = await fetch('https://api.securimail.io/account', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();

      if (response.status === 403) {
        setUser({ 
          ...data,
          isAuthenticated: true,
          setupCompleted: false,
          token 
        });
        navigate('/account/setup');
      } else if (response.ok) {
        localStorage.setItem('user_email', data.email);
        localStorage.setItem('user_id', data.id);
        
        setUser({
          ...data,
          isAuthenticated: true,
          setupCompleted: true,
          token
        });
      } else {
        await handleLogout();
      }
    } catch (error) {
      await handleLogout();
    } finally {
      setLoading(false);
    }
};

  const handleLogin = async (token, userData) => {
    // Store all necessary user data
    localStorage.setItem('securimail_token', token);
    localStorage.setItem('user_email', userData.email);
    localStorage.setItem('user_id', userData.id);
    
    // Check keyStatus directly from API response
    const needsSetup = userData.keyStatus === 'not-configured';
    
    setUser({
      ...userData,
      isAuthenticated: true,
      setupCompleted: !needsSetup,
      token
    });
  
    // Navigation based on keyStatus
    if (needsSetup) {
      navigate('/account/setup');
    } else {
      navigate('/account');
    }
  };

  const handleSignup = async (userData, checkoutSessionId) => {
    try {
      trackConversion('EPxbCJWslfEZEJiNxMs-');
      setSignupData(userData);
      sessionStorage.setItem('pending_signup', JSON.stringify(userData));
      
      if (checkoutSessionId) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        if (!stripe) throw new Error('Failed to load Stripe');
        
        const { error } = await stripe.redirectToCheckout({ sessionId: checkoutSessionId });
        if (error) throw error;
      } else {
        // For free plan
        navigate('/account/setup');
      }
    } catch (error) {
      console.error('Signup error:', error);
      setSignupData(null);
      sessionStorage.removeItem('pending_signup');
      throw error;
    }
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('securimail_token');
    if (token) {
      try {
        await fetch('https://api.securimail.io/logout', {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${token}` }
        });
      } catch (error) {
        console.error('Logout error:', error);
      }
    }

    // Clean up all auth-related data
    localStorage.removeItem('securimail_token');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_id');
    sessionStorage.removeItem('pending_signup');
    setUser(null);
    setSignupData(null);
    navigate('/login');
  };

  const completeSignup = async (token, userData) => {
    try {
      localStorage.setItem('securimail_token', token);
      localStorage.setItem('user_email', userData.email);
      localStorage.setItem('user_id', userData.id);
      sessionStorage.removeItem('pending_signup');
      setUser(userData);
      setSignupData(null);
      navigate('/account/setup');
    } catch (error) {
      console.error('Complete signup error:', error);
      await handleLogout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      signupData,
      handleLogin,
      handleSignup,
      handleLogout,
      completeSignup,
      isAuthenticated: !!user,
      isCompletingSignup: !!signupData,
      checkAuth
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};