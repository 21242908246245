import React, { useState } from 'react';
import { handleNewUserCheckout } from '../utils/checkoutUtils';

const CallToAction = () => {
  const [loading, setLoading] = useState(false);
  const handleCheckout = () => handleNewUserCheckout(setLoading);

  return (
    <div className="bg-indigo-600 text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold mb-4">Get Started with SecuriMail for Free</h2>
        <p className="text-lg mb-6">
          Join the free tier and experience privacy-focused email protection without cost.
        </p>
        <button
          onClick={handleCheckout}
          className="bg-white text-indigo-600 font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-100 transition"
        >
          Sign Up Free
        </button>
      </div>
    </div>
  );
};

export default CallToAction;
