import React from 'react';
import SEOSchema from './SEOSchema';

const About = () => {

  return (
    <>
    <SEOSchema page="about" />
    <div className="max-w-7xl mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">About SecuriMail</h1>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
        <p className="text-gray-600 mb-4">
          SecuriMail is dedicated to revolutionizing email privacy and management. Our mission is to empower users with complete control over their digital communications while maintaining simplicity and ease of use. We believe privacy shouldn't come at the cost of convenience.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">What We Offer</h2>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h3 className="text-xl font-medium mb-2">Email Masking Service</h3>
            <p className="text-gray-600">
              Our core service provides disposable email masks that forward to your real email address, allowing you to maintain privacy while staying connected. Each mask can be managed, monitored, or deleted at any time, giving you complete control over your inbox.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-medium mb-2">Browser Integration</h3>
            <p className="text-gray-600">
              With our browser extension, creating new email masks is as simple as clicking a button. Generate unique masks instantly while signing up for services or sharing your contact information online.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Privacy & Security</h2>
        <p className="text-gray-600 mb-4">
          Security is at the heart of everything we do. Our infrastructure is built with industry-leading encryption standards, and we never store your original email contents. All mask forwarding is done in real-time with zero persistence of message data.
        </p>
        <p className="text-gray-600">
          We're committed to transparency and user control. You can review our complete privacy policy to understand exactly how your data is handled and protected.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Company Information</h2>
        <p className="text-gray-600 mb-4">
          SecuriMail is a product of Securitan LLC, founded by Anthony Zoblescin. As a company, we specialize in developing privacy-focused solutions that help individuals and businesses maintain control over their digital footprint.
        </p>
        <p className="text-gray-600">
          Based in the United States, we serve clients globally, adhering to international data protection standards and maintaining strict privacy practices across all our operations.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="text-gray-600">
          Have questions about SecuriMail or want to learn more about our services? Our support team is ready to help. Contact us through our secure contact form or visit our help center for immediate assistance.
        </p>
      </section>
    </div>
    </>
  );
};

export default About;