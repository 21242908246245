import React from "react";

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12">
      <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      <stripe-pricing-table 
        pricing-table-id="prctbl_1QSWiiC4G1ZEPm8HwxlcqZTI" 
        publishable-key="pk_live_51QOiTRC4G1ZEPm8HZmGkvjV0IU8EHSDxCJhuNbC92t2EkISECb0AbDmjR2GjSzRlwNU6t9PQsQUZfVWW4JRImZlw001usKZC4e">
      </stripe-pricing-table>
    </div>
  );
};

export default PricingPage;
