// Features.jsx
import React, { useState } from 'react';
import { ChevronDown, ChevronRight, Shield } from 'lucide-react';
import SEOSchema from './SEOSchema';

const Features = () => {
  const [expandedCategories, setExpandedCategories] = useState({
    pricing: true,
    email: true,
    security: true,
    analytics: true,
    access: true,
    support: true
  });

  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  const getColor = (value) => {
    if (value === '✓') return 'bg-green-100 text-green-800';
    if (value === '-') return 'bg-gray-100 text-gray-400';
    if (value === 'Basic') return 'bg-blue-50 text-blue-800';
    if (value === 'Enhanced' || value === 'Advanced') return 'bg-blue-100 text-blue-800';
    if (value === 'Enterprise') return 'bg-purple-100 text-purple-800';
    if (value === 'Limited') return 'bg-yellow-100 text-yellow-800';
    if (value === 'Full') return 'bg-green-200 text-green-800';
    return 'bg-white';
  };

  const pricing = {
    'Free': 'Free',
    'Basic': '$3.99/month',
    'Pro': '$9.99/month',
    'Pro Premium': '$19.99/month',
    'Business': 'Custom'
  };

  const categories = {
    email: {
      title: 'Email Management',
      features: {
        'Active Masks': ['2', '10', '25', 'Unlimited', 'Unlimited'],
        'Email Forwarding Limit': ['100/mo', '500/mo', '2000/mo', '5000/mo', '5000 per user'],
        'Custom Labels': ['-', '-', '✓', '✓', '✓'],
        'Mask Rules': ['On/Off', 'Basic', 'Advanced', 'Custom', 'Custom'],
        'Dark Mode': ['✓', '✓', '✓', '✓', '✓'],
        'Browser Extension': ['✓', '✓', '✓', '✓', '✓']
      }
    },
    security: {
      title: 'Security Features',
      features: {
        'End-to-end Encryption': ['✓', '✓', '✓', '✓', '✓'],
        'Domain-based Filtering': ['-', '✓', '✓', '✓', '✓'],
        'Custom Security Rules': ['-', '-', '-', '-', '✓'],
        'Real-time Threat Analysis': ['-', '-', '-', '✓', '✓'],
        'Spam Filtering': ['-', 'Basic', 'Enhanced', 'Advanced', 'Enterprise'],
        'Virus Scanning': ['-', '-', 'Basic', 'Advanced', 'Enterprise'],
        '2FA': ['-', '-', '-', '-', '✓'],
        'SSO': ['-', '-', '-', '-', '✓']
      }
    },
    analytics: {
      title: 'Analytics & Reporting',
      features: {
        'Analytics Dashboard': ['-', '✓', '✓', '✓', '✓'],
        'Analytics History': ['-', '30 days', '1 year', '2 years', 'Unlimited'],
        'Usage Reports': ['-', '-', 'Basic', 'Custom', 'Custom'],
        'Export Data': ['-', '-', 'CSV', 'Multiple', 'API'],
        'Team Analytics': ['-', '-', '-', '-', '✓']
      }
    },
    access: {
      title: 'Access Methods',
      features: {
        'Web Dashboard': ['✓', '✓', '✓', '✓', '✓'],
        'Browser Extension': ['✓', '✓', '✓', '✓', '✓'],
        'Mobile App (iOS/Android)': ['-', '-', '-', '✓', '✓'],
        'API Access': ['-', '-', '-', 'Limited', 'Full'],
        'Team Management Portal': ['-', '-', '-', '-', '✓']
      }
    },
    support: {
      title: 'Support & Additional Features',
      features: {
        'Support Response Time': ['-', '48h', '24h', '4h', '1h'],
        'Integrations': ['-', '-', '-', '✓', '✓'],
        'Custom Rules Engine': ['-', '-', '-', '✓', '✓'],
        'Priority Support': ['-', '-', '-', '✓', '✓'],
        'Custom Implementation': ['-', '-', '-', '-', '✓']
      }
    }
  };

  return (
    <>
    <SEOSchema page="features" />
    <div className="min-h-screen bg-slate-50 pt-16">
      {/* Navigation */}
      <nav className="bg-slate-900 text-white p-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Shield className="h-8 w-8 text-purple-400" />
            <span className="text-xl font-bold">SecuriMail</span>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-6">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">SecuriMail Plans & Features</h2>
        
        {/* Pricing Header */}
        <div className="grid grid-cols-6 gap-4 mb-8">
          <div className="col-span-1"></div>
          {Object.entries(pricing).map(([tier, price]) => (
            <div key={tier} className="text-center">
              <div className={`text-lg font-semibold mb-2 ${tier === 'Pro Premium' ? 'text-purple-600' : ''}`}>{tier}</div>
              <div className="text-sm text-gray-600">{price}</div>
            </div>
          ))}
        </div>

        {/* Feature Categories */}
        <div className="space-y-6">
          {Object.entries(categories).map(([key, category]) => (
            <div key={key} className="border rounded-lg overflow-hidden">
              <button
                className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100"
                onClick={() => toggleCategory(key)}
              >
                <h3 className="text-lg font-semibold text-gray-800">{category.title}</h3>
                {expandedCategories[key] ? <ChevronDown className="h-5 w-5" /> : <ChevronRight className="h-5 w-5" />}
              </button>
              {expandedCategories[key] && (
                <div className="p-4 overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Feature</th>
                        <th className="px-4 py-2 text-center text-sm font-medium text-gray-500">Free</th>
                        <th className="px-4 py-2 text-center text-sm font-medium text-gray-500">Basic</th>
                        <th className="px-4 py-2 text-center text-sm font-medium text-gray-500">Pro</th>
                        <th className="px-4 py-2 text-center text-sm font-medium text-gray-500">Pro Premium</th>
                        <th className="px-4 py-2 text-center text-sm font-medium text-gray-500">Business</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {Object.entries(category.features).map(([feature, values]) => (
                        <tr key={feature} className="hover:bg-gray-50">
                          <td className="px-4 py-2 text-sm font-medium text-gray-900">{feature}</td>
                          {values.map((value, index) => (
                            <td key={index} className="px-4 py-2 text-center">
                              <span className={`inline-block px-2 py-1 rounded-full text-xs font-medium ${getColor(value)}`}>
                                {value}
                              </span>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Features;