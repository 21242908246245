import React from 'react';

const Footer = () => {
  const footerSections = [
    {
      title: "PRODUCT",
      links: [
        { name: "Features", href: "/features" },
        { name: "Pricing", href: "/pricing" },
        { name: "Downloads", href: "/downloads" }
      ]
    },
    {
      title: "COMPANY",
      links: [
        { name: "About", href: "/about" },
        { name: "Blog", href: "/blog" },
        { name: "Privacy Policy", href: "/privacy-policy" }
      ]
    },
    {
      title: "SUPPORT",
      links: [
        { name: "Help Center", href: "/help-center" },
        { name: "FAQ", href: "/faq" },
        { name: "Status", href: "https://securimail.instatus.com/" }
      ]
    }
  ];

  return (
    <footer className="bg-gray-900 py-16" id="contact">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-3 gap-8 mb-24">
          {footerSections.map((section, index) => (
            <div key={index} className="flex flex-col items-center">
              <h3 className="text-gray-400 text-sm font-medium mb-4 text-center">
                {section.title}
              </h3>
              <ul className="space-y-3 text-center">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a 
                      href={link.href} 
                      className="text-gray-300 hover:text-white transition-colors"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="border-t border-gray-800 pt-16">
          <div className="flex items-center justify-center space-x-4">
            <img 
              src="/icononly_transparent_nobuffer.png" 
              alt="SecuriMail Logo" 
              className="h-32"
            />
            <p className="text-gray-400 text-sm">
              © 2024 <span className="text-blue-400">Securi</span>Mail. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;