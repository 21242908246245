import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

export const ProtectedRoute = ({ children, requireSetup = true }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requireSetup && 
    user?.keyStatus === 'not-configured' && 
    location.pathname !== '/account/setup' && 
    location.pathname !== '/login') {
  return <Navigate to="/account/setup" replace />;
}
  
  return children;
};

export const PublicOnlyRoute = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  // Only redirect on login/signup pages when already authenticated
  if (isAuthenticated && (location.pathname === '/login' || location.pathname === '/signup')) {
    if (user?.keyStatus === 'not-configured') {
      return <Navigate to="/account/setup" replace />;
    }
    return <Navigate to="/account" state={{ from: location }} replace />;
  }

  return children;
};

export const SignupRoute = ({ children }) => {
  const { isAuthenticated, isCompletingSignup } = useAuth();
  const location = useLocation();

  // If not in signup process and not authenticated, redirect to login
  if (!isCompletingSignup && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If already authenticated, redirect to account
  if (isAuthenticated && !isCompletingSignup) {
    return <Navigate to="/account" state={{ from: location }} replace />;
  }

  return children;
};