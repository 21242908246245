// src/utils/useRateLimit.js

import { useLocalStorageWithTTL } from './localStorage';

export const useRateLimit = (maskId) => {
  const [getCachedData, setCachedData] = useLocalStorageWithTTL(`rate_limit_${maskId}`, 60000); // 1 minute TTL for rate limits

  const updateRateLimits = (headers) => {
    const limits = {
      burst: {
        remaining: parseInt(headers.get('X-RateLimit-Burst-Remaining') || '0'),
        limit: parseInt(headers.get('X-RateLimit-Burst-Limit') || '0')
      },
      hourly: {
        remaining: parseInt(headers.get('X-RateLimit-Hourly-Remaining') || '0'),
        limit: parseInt(headers.get('X-RateLimit-Hourly-Limit') || '0')
      },
      nextReset: parseInt(headers.get('X-RateLimit-Reset') || '0'),
      isLimited: headers.get('X-RateLimit-Limited') === 'true'
    };

    setCachedData(limits);
    return limits;
  };

  const getRateLimits = () => {
    return getCachedData() || null;
  };

  const formatTimeRemaining = (resetTime) => {
    if (!resetTime) return null;
    const now = Date.now();
    const remaining = resetTime - now;
    
    if (remaining <= 0) return 'Rate limit reset';
    
    const minutes = Math.floor(remaining / 60000);
    const seconds = Math.ceil((remaining % 60000) / 1000);
    
    if (minutes > 0) {
      return `Reset in ${minutes}m ${seconds}s`;
    }
    return `Reset in ${seconds}s`;
  };

  return {
    updateRateLimits,
    getRateLimits,
    formatTimeRemaining
  };
};