import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './auth/AuthContext';
import { ProtectedRoute, PublicOnlyRoute } from './auth/ProtectedRoute';
import Navigation from './components/nav.jsx';
import Hero from './components/hero.jsx';
import Features from './components/features.jsx';
import Pricing from './components/pricing.jsx';
import Footer from './components/footer.jsx';
import PrivacyPolicy from "./components/privacypolicy-index.jsx";
import About from "./components/About";
import Overview from "./components/Overview";
import FAQ from "./components/FAQ";
import Account from "./components/Account";
import Blog from './components/Blog';
import Login from './components/Login';
import AccountSetup from './components/AccountSetup';
import MarketingFeatures from './components/MarketingFeatures';
import FeatureCases from './components/FeatureCases';
import { StripeSuccess, StripeCancel } from './components/StripeResult';
import CallToAction from './components/CallToAction';
import Testimonials from './components/Testimonials';
import CampaignLanding from './components/CampaignLanding';

// Layout component for public pages
const PublicLayout = () => (
  <>
    <Navigation />
    <Outlet />
    <Footer />
  </>
);

// Layout component for protected pages
const ProtectedLayout = () => (
  <ProtectedRoute>
    <Navigation />
    <Outlet />
    <Footer />
  </ProtectedRoute>
);


const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        {/* Marketing Campaign Routes - No header/footer */}
        <Route path="/privacy" element={<CampaignLanding campaign="privacy" />} />
        <Route path="/spam" element={<CampaignLanding campaign="spam" />} />
        <Route path="/temporary" element={<CampaignLanding campaign="temporary" />} />

        {/* Auth Routes */}
        <Route path="/login" element={
          <PublicOnlyRoute>
            <Navigation />
            <Login />
            <Footer />
          </PublicOnlyRoute>
        } />
        
        {/* Protected Account Routes */}
        <Route element={<ProtectedLayout />}>
          <Route path="/account/*" element={<Account />} />
          <Route path="/account/setup" element={<AccountSetup />} />
          <Route path="/signup/success" element={<StripeSuccess />} />
        </Route>

        {/* Public Marketing Routes with Nav/Footer */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={
            <>
              <Hero />
              <Overview />
              <CallToAction />
              <Features />
              <CallToAction />
              <Pricing />
            </>
          } />
          <Route path="/features" element={
            <>
              <Overview />
              <CallToAction />
              <MarketingFeatures />
              <CallToAction />
              <Features />
              <CallToAction />
              <FeatureCases />
              <CallToAction />
            </>
          } />
          <Route path="/pricing" element={
            <>
              <MarketingFeatures />
              <CallToAction />
              <Testimonials />
              <CallToAction />
              <Pricing />
            </>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blog/*" element={<Blog />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/signup/cancel" element={<StripeCancel />} />
        </Route>
      </Routes>
    </AuthProvider>
  </Router>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

export default App;