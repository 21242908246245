export const useLocalStorageWithTTL = (key, ttl = 300000) => {
    const getCachedData = () => {
      const item = localStorage.getItem(key);
      if (!item) return null;
  
      const { value, timestamp } = JSON.parse(item);
      if (Date.now() - timestamp > ttl) {
        localStorage.removeItem(key);
        return null;
      }
  
      return value;
    };
  
    const setCachedData = (value) => {
      const item = {
        value,
        timestamp: Date.now(),
      };
      localStorage.setItem(key, JSON.stringify(item));
    };
  
    return [getCachedData, setCachedData];
  };  