import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogIndex from './Blog/index';
import BlogPost from './Blog/BlogPost';
import SEOSchema from './SEOSchema';

const Blog = () => {
  console.log('Blog component rendering'); // Add logging
  
  return (
    <>
    <SEOSchema page="blog" />
    <div className="min-h-screen bg-white">
    <Routes>
      <Route path="/" element={<BlogIndex />} />
      <Route path=":slug" element={<BlogPost />} />
    </Routes>
    </div>
    </>
  );
};

export default Blog;