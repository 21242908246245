import React from 'react';
import { Shield, Mail, Lock, Fingerprint, ArrowRight, ShoppingBag, Briefcase, Share2, UserX, AlertTriangle, ShieldCheck, Heart, Zap, Check } from 'lucide-react';

// Campaign data moved to separate variable for clarity
const campaigns = {
  privacy: {
    headline: "Protect Your Email Privacy",
    subheadline: "Keep your real email private with secure forwarding",
    features: [
      {
        title: "Complete Email Privacy",
        description: "Privacy email protection that keeps your real address completely hidden. Perfect for protecting your identity online.",
        icon: Lock
      },
      {
        title: "Secure Email Forwarding",
        description: "Your emails forward securely while maintaining privacy. Take control of who has access to your real email.",
        icon: Mail
      },
      {
        title: "Privacy Controls",
        description: "Advanced email privacy settings give you complete control over your communications.",
        icon: Shield
      }
    ],
    useCases: [
      {
        icon: ShoppingBag,
        title: "Online Shopping",
        problem: "Want to protect your email privacy while shopping?",
        solution: "Create private email addresses for each store. Protect email privacy and track who shares your info."
      },
      {
        icon: Briefcase,
        title: "Professional Use",
        problem: "Need to maintain email privacy at work?",
        solution: "Use secure email forwarding for professional communications while keeping your real address private."
      }
    ],
    threats: [
      {
        icon: AlertTriangle,
        title: "Data Mining",
        description: "Companies track and sell your email data",
        solution: "Our privacy protection keeps your real email hidden"
      },
      {
        icon: ShieldCheck,
        title: "Identity Theft",
        description: "Your email is the key to your accounts",
        solution: "Email privacy protection keeps you secure"
      }
    ],
    highlight: "Email Privacy Features",
    highlightFeatures: [
      "Complete email privacy protection",
      "Secure email forwarding",
      "Advanced privacy controls",
      "Real-time privacy monitoring"
    ]
  },

  spam: {
    headline: "Stop Spam Emails Forever",
    subheadline: "Block spam and keep your inbox clean with our advanced spam filter",
    features: [
      {
        title: "Advanced Spam Filter",
        description: "Our powerful spam filter blocks unwanted emails automatically. Stop spam before it reaches your inbox.",
        icon: Shield
      },
      {
        title: "Block Spam Instantly",
        description: "Learn how to stop junk mail with our intelligent filtering system. Block spam emails with one click.",
        icon: Lock
      },
      {
        title: "Smart Protection",
        description: "Advanced email spam filter that learns and adapts to new threats. Complete spam protection.",
        icon: Zap
      }
    ],
    useCases: [
      {
        icon: Mail,
        title: "Clean Inbox",
        problem: "Tired of constant spam emails?",
        solution: "Our spam filter blocks unwanted messages automatically. Stop spam emails instantly."
      },
      {
        icon: ShieldCheck,
        title: "Business Protection",
        problem: "Need to block spam for your whole team?",
        solution: "Enterprise-grade spam protection keeps everyone's inbox clean."
      }
    ],
    threats: [
      {
        icon: AlertTriangle,
        title: "Phishing Attacks",
        description: "Spam emails often contain malicious links",
        solution: "Our spam filter blocks dangerous content"
      },
      {
        icon: Heart,
        title: "Inbox Overload",
        description: "Spam makes important emails hard to find",
        solution: "Block spam and see what matters"
      }
    ],
    highlight: "Spam Protection Features",
    highlightFeatures: [
      "Advanced spam filter",
      "Automatic spam blocking",
      "Custom filter rules",
      "Spam reporting tools"
    ]
  },

  temporary: {
    headline: "Create Temporary Email Addresses",
    subheadline: "Generate disposable email addresses that forward to your real inbox",
    features: [
      {
        title: "Temporary Email Generator",
        description: "Create temporary email addresses instantly. Best temp mail service for quick signups and trials.",
        icon: Mail
      },
      {
        title: "Disposable Email Addresses",
        description: "Generate unlimited disposable email addresses. Perfect temporary email solution for any situation.",
        icon: Lock
      },
      {
        title: "Burner Email Control",
        description: "Create temporary email addresses that you can delete anytime. Full control over your disposable emails.",
        icon: Shield
      }
    ],
    useCases: [
      {
        icon: Share2,
        title: "Quick Signups",
        problem: "Need a disposable email address for testing?",
        solution: "Create temporary email addresses instantly with our temp email generator."
      },
      {
        icon: UserX,
        title: "Online Protection",
        problem: "Want to try services without risk?",
        solution: "Use our temporary email generator for safer online experiences."
      }
    ],
    threats: [
      {
        icon: AlertTriangle,
        title: "Spam Signups",
        description: "Real email exposed to marketing lists",
        solution: "Use disposable email addresses instead"
      },
      {
        icon: ShieldCheck,
        title: "Account Testing",
        description: "Need temporary access for trials",
        solution: "Create temporary email instantly"
      }
    ],
    highlight: "Temporary Email Features",
    highlightFeatures: [
      "Instant email generation",
      "Unlimited disposable addresses",
      "Auto-forwarding setup",
      "One-click deletion"
    ]
  }
};

const CampaignLanding = ({ campaign = 'privacy' }) => {
  const currentCampaign = campaigns[campaign];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
          <div className="text-center">
          <img className="max-w-sm w-full object-contain mx-auto" src="/FullLogo_Transparent.svg" alt="Securitan Logo"/>
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl mb-4">
              {currentCampaign.headline}
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
              {currentCampaign.subheadline}
            </p>
            <div className="flex justify-center gap-4">
              <button className="bg-indigo-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-indigo-700 transition-colors">
                Start Free Trial
              </button>
              <button className="border border-indigo-600 text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition-colors">
                Watch Demo
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">1</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Sign Up Free</h3>
              <p className="text-gray-600">Create your account in seconds</p>
            </div>
            <ArrowRight className="hidden md:block h-6 w-6 text-gray-400" />
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">2</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Set Up Protection</h3>
              <p className="text-gray-600">Customize your security settings</p>
            </div>
            <ArrowRight className="hidden md:block h-6 w-6 text-gray-400" />
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">3</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Stay Protected</h3>
              <p className="text-gray-600">Enjoy a cleaner, safer inbox</p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Features */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {currentCampaign.features.map((feature, index) => (
              <div key={index} className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
                <feature.icon className="h-8 w-8 text-indigo-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Use Cases */}
      <div className="bg-white py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">Perfect For</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {currentCampaign.useCases.map((useCase, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-6">
                <div className="flex items-start space-x-4">
                  <div className="bg-indigo-100 rounded-full p-3">
                    <useCase.icon className="h-6 w-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">{useCase.title}</h3>
                    <p className="text-gray-500 mb-3">{useCase.problem}</p>
                    <p className="text-gray-700">{useCase.solution}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Feature Highlight & CTA */}
      <div className="bg-gray-50 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-indigo-900 rounded-xl p-8 text-white">
            <h2 className="text-3xl font-bold text-center mb-8">
              {currentCampaign.highlight}
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <ul className="space-y-4">
                  {currentCampaign.highlightFeatures.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <Check className="h-5 w-5 text-indigo-400 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col items-center justify-center">
                <button className="bg-white text-indigo-600 px-8 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition-colors mb-4">
                  Start Free Trial
                </button>
                <p className="text-indigo-200 text-sm">No credit card required</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignLanding;