import React, { useState, useEffect } from 'react';
import { Shield, Key, Mail, Check } from 'lucide-react';
import { useAuth } from '../auth/AuthContext';
import { setupKeys, createMaskAPI } from '../auth/api';
import { useNavigate } from 'react-router-dom';

const AccountSetup = () => {
  const [step, setStep] = useState('welcome');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('securimail_token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);

  const steps = {
    welcome: {
      title: 'Welcome to SecuriMail',
      description: 'Let\'s get your account set up for maximum security.',
      action: () => setStep('security')
    },
    security: {
      title: 'Secure Your Account', 
      description: 'Set up encryption keys for your email masks.',
      action: async () => {
        setLoading(true);
        setError('');
        try {
          await setupKeys();
          setStep('masks');
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    masks: {
      title: 'Create Your First Mask',
      description: 'Start protecting your inbox with an email mask.',
      action: async (maskData) => {
        setLoading(true);
        setError('');
        try {
          await createMaskAPI(maskData.description, maskData.forwardTo);
          setStep('complete');
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    complete: {
      title: 'All Set!', 
      description: 'Your secure email environment is ready.',
      action: () => navigate('/account')
    }
  };

 const currentStep = steps[step];

 if (!user) {
   return null;
 }

 return (
   <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
     <div className="max-w-md w-full space-y-8 mx-auto">
       <div className="text-center">
         <Shield className="mx-auto h-12 w-12 text-indigo-600" />
         <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
           {currentStep.title}
         </h2>
         <p className="mt-2 text-sm text-gray-600">
           {currentStep.description}
         </p>
       </div>

       {error && (
         <div className="bg-red-50 border-l-4 border-red-400 p-4">
           <p className="text-sm text-red-700">{error}</p>
         </div>
       )}

       <div className="mt-8">
         {step === 'welcome' && (
           <button
             onClick={currentStep.action}
             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
           >
             Get Started
           </button>
         )}

         {step === 'security' && (
           <button
             onClick={currentStep.action}
             disabled={loading}
             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
           >
             {loading ? 'Setting up...' : 'Set Up Encryption Keys'}
           </button>
         )}

         {step === 'masks' && (
           <CreateMaskForm onSubmit={currentStep.action} loading={loading} />
         )}

         {step === 'complete' && (
           <button
             onClick={currentStep.action}
             className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
           >
             Go to Dashboard
           </button>
         )}
       </div>
     </div>
   </div>
 );
};

const CreateMaskForm = ({ onSubmit, loading }) => {
 const [description, setDescription] = useState('');
 const [forwardTo, setForwardTo] = useState('');

 const handleSubmit = (e) => {
   e.preventDefault();
   onSubmit({ description, forwardTo });
 };

 return (
   <form onSubmit={handleSubmit} className="space-y-6">
     <div>
       <label htmlFor="description" className="block text-sm font-medium text-gray-700">
         Mask Description
       </label>
       <input
         type="text"
         value={description}
         onChange={(e) => setDescription(e.target.value)}
         className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
         placeholder="e.g., Shopping, Social Media"
         required
       />
     </div>

     <div>
       <label htmlFor="forwardTo" className="block text-sm font-medium text-gray-700">
         Forward To
       </label>
       <input
         type="email"
         value={forwardTo}
         onChange={(e) => setForwardTo(e.target.value)}
         className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
         placeholder="your@email.com"
         required
       />
     </div>

     <button
       type="submit"
       disabled={loading}
       className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
     >
       {loading ? 'Creating...' : 'Create Mask'}
     </button>
   </form>
 );
};

export default AccountSetup;