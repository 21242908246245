import React, { useState } from 'react';
import { Shield, Mail, ArrowRight, Package } from 'lucide-react';
import { useAuth } from '../auth/AuthContext';


const Login = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('email'); // email -> verify -> plan
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [pricingTable, setPricingTable] = useState(null);
  const { handleLogin, handleSignup } = useAuth();
  const [requires2FA, setRequires2FA] = useState(false);
  const [tempToken, setTempToken] = useState(null);
  const [twoFactorCode, setTwoFactorCode] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await fetch('https://api.securimail.io/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email.trim().toLowerCase() })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      setStep('verify');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      const response = await fetch('https://api.securimail.io/login', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          otp: otp.trim()
        })
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
  
      // Handle different response statuses
      switch (data.status) {
        case 'plan_selection_required':
          setStep('plan');
          setPricingTable(data.pricingTable);
          break;
        case 'requires_2fa':
          setStep('2fa');
          setTempToken(data.tempToken); // Store temporary token for 2FA verification
          break;
        default:
          handleLogin(data.token, data.user);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handle2FAVerify = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    try {
      const response = await fetch('https://api.securimail.io/login/verify2fa', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          code: twoFactorCode.trim(),
          tempToken
        })
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      
      handleLogin(data.token, data.user);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanSelection = async (planId) => {
    setError('');
    setLoading(true);

    try {
      const response = await fetch('https://api.securimail.io/signup/complete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          planId
        })
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error);

      if (data.status === 'payment_required') {
        handleSignup(data.userData, data.sessionId);
      } else {
        handleLogin(data.token, data.user);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <Shield className="h-12 w-12 text-indigo-600" />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {step === 'plan' ? 'Choose Your Plan' : 'Sign in to SecuriMail'}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-4">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}

          {step === 'email' && (
            <form onSubmit={handleEmailSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    required
                  />
                  <Mail className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {loading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>Continue <ArrowRight className="ml-2 h-5 w-5" /></>
                )}
              </button>
            </form>
          )}
          {step === '2fa' && (
            <form onSubmit={handle2FAVerify} className="space-y-6">
              <div>
                <label htmlFor="2fa" className="block text-sm font-medium text-gray-700">
                  2FA Verification Code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    inputMode="numeric"
                    value={twoFactorCode}
                    onChange={(e) => setTwoFactorCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter 6-digit code"
                    required
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Enter the code from your authenticator app
                </p>
              </div>

              <button
                type="submit"
                disabled={loading || twoFactorCode.length !== 6}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {loading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Verify 2FA'
                )}
              </button>
            </form>
          )}
          {step === 'verify' && (
            <form onSubmit={handleVerifyOTP} className="space-y-6">
              <div>
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    inputMode="numeric"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value.replace(/\D/g, '').slice(0, 6))}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Enter 6-digit code"
                    required
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  We sent a code to {email}
                </p>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setStep('email')}
                  className="text-sm text-indigo-600 hover:text-indigo-500"
                >
                  Use different email
                </button>
                <button
                  type="button"
                  onClick={handleEmailSubmit}
                  className="text-sm text-indigo-600 hover:text-indigo-500"
                >
                  Resend code
                </button>
              </div>

              <button
                type="submit"
                disabled={loading || otp.length !== 6}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
              >
                {loading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Verify'
                )}
              </button>
            </form>
          )}

        {step === 'plan' && (
                <div className="w-full max-w-4xl mx-auto">
                  <stripe-pricing-table
                    pricing-table-id="prctbl_1QSWiiC4G1ZEPm8HwxlcqZTI"
                    publishable-key="pk_live_51QOiTRC4G1ZEPm8HZmGkvjV0IU8EHSDxCJhuNbC92t2EkISECb0AbDmjR2GjSzRlwNU6t9PQsQUZfVWW4JRImZlw001usKZC4e">
                  </stripe-pricing-table>
                </div>
              )}
        </div>
      </div>
    </div>
  );
};

export default Login;