import React, { useState } from 'react';
import { handleNewUserCheckout } from '../utils/checkoutUtils';
import SEOSchema from './SEOSchema';

const Hero = () => {
  const [loading, setLoading] = useState(false);

  const handleStartTrial = () => handleNewUserCheckout(setLoading);

  return (
    <>
    <SEOSchema page="hero" />
    <div className="relative bg-white overflow-hidden pt-16">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Protect your inbox with</span>
                <span className="block text-indigo-600">SecuriMail</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Create disposable email masks that forward to your real inbox. Take control of your online privacy and stop spam forever.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <button 
                    onClick={handleStartTrial}
                    disabled={loading}
                    className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 ${
                      loading ? 'opacity-75 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? 'Loading...' : 'Start Free Trial'}
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div className="flex items-center justify-center h-full px-4">
          <img
            className="max-w-sm w-full object-contain mx-auto"
            src="/FullLogo_Transparent.svg"
            alt="Securitan Logo"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default Hero;