import React, { useState, useEffect } from 'react';
import { Shield, Mail, CreditCard, Lock, TrashIcon, RefreshCw,AlertTriangle, TrendingUp } from 'lucide-react';
import { useLocalStorageWithTTL } from '../utils/localStorage';
import { useRateLimit } from '../utils/useRateLimit';
import { 
  fetchAccountAndMasks, 
  createMaskAPI, 
  deleteMaskAPI,
  updateMaskAPI,
  setupTwoFactorAPI,
  verifyTwoFactorAPI,
  fetchBillingPortal,
  initiateCheckoutSession 
} from '../auth/api';


// Alert Component
const Alert = ({ message, type, onClose }) => {
  const colors = {
    error: 'bg-red-50 text-red-800 border-red-200',
    success: 'bg-green-50 text-green-800 border-green-200',
    warning: 'bg-yellow-50 text-yellow-800 border-yellow-200',
    info: 'bg-blue-50 text-blue-800 border-blue-200'
  };

  return (
    <div className={`fixed top-4 right-4 z-50 rounded-lg border p-4 shadow-lg ${colors[type]}`}>
      <div className="flex items-center justify-between">
        <p>{message}</p>
        <button 
          onClick={onClose}
          className="ml-4 hover:opacity-70"
        >
          ×
        </button>
      </div>
    </div>
  );
};

// Modal Component
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ×
          </button>
        </div>
        <div className="p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

// Create Mask Modal Content
const CreateMaskModal = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({ description: '', forwardTo: '' });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');

  const validateForm = () => {
    const newErrors = {};
    if (!formData.description) {
      newErrors.description = 'Description is required';
    }
    if (!formData.forwardTo) {
      newErrors.forwardTo = 'Forwarding email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.forwardTo)) {
      newErrors.forwardTo = 'Invalid email format';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmailAsync = async (email) => {
    setIsLoading(true);
    setApiError('');
    try {
      const response = await fetch(`https://emailvalidation.abstractapi.com/v1/?api_key=d5396259f2d3404a8ff0b5b48f033973&email=${email}`);
      if (!response.ok) throw new Error('Failed to validate email');
      const result = await response.json();

      if (!result.is_valid_format.value || result.is_disposable_email.value) {
        throw new Error('Invalid or disposable email address');
      }
      return true;
    } catch (error) {
      setApiError(error.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const isValidEmail = await validateEmailAsync(formData.forwardTo);
      if (isValidEmail) {
        onSubmit(formData.description, formData.forwardTo);
        setFormData({ description: '', forwardTo: '' });
        onClose();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Create New Mask">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input
            type="text"
            value={formData.description}
            onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Enter mask description"
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">{errors.description}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Forward To
          </label>
          <input
            type="email"
            value={formData.forwardTo}
            onChange={(e) => setFormData((prev) => ({ ...prev, forwardTo: e.target.value }))}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Enter forwarding email"
          />
          {errors.forwardTo && (
            <p className="mt-1 text-sm text-red-600">{errors.forwardTo}</p>
          )}
          {apiError && <p className="mt-1 text-sm text-red-600">{apiError}</p>}
        </div>

        {isLoading && (
          <div className="flex justify-center mt-4">
            <div className="loader border-t-4 border-indigo-600 rounded-full w-6 h-6 animate-spin"></div>
          </div>
        )}

        <div className="flex justify-end gap-2 mt-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            disabled={isLoading}
          >
            {isLoading ? 'Validating...' : 'Create'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

// Switch Component
const Switch = ({ checked, onCheckedChange }) => (
  <button
    type="button"
    onClick={() => onCheckedChange(!checked)}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
      checked ? 'bg-indigo-600' : 'bg-gray-200'
    }`}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        checked ? 'translate-x-6' : 'translate-x-1'
      }`}
    />
  </button>
);

// Checkbox Component
const Checkbox = ({ checked, onCheckedChange }) => (
  <button
    type="button"
    onClick={() => onCheckedChange(!checked)}
    className={`h-4 w-4 rounded border transition-colors ${
      checked ? 'bg-indigo-600 border-indigo-600' : 'bg-white border-gray-300'
    }`}
  >
    {checked && (
      <svg className="h-4 w-4 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <path d="M20 6L9 17L4 12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )}
  </button>
);

// Stats Card Component
// Enhanced StatsCard Component
const StatsCard = ({ 
  title, 
  mainStat, 
  subStat, 
  current, 
  limit,
  showProgress = false,
  isExceeded = false,
}) => {
  const percentage = limit ? Math.min((current / limit * 100), 100) : 0;
  
  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${
      isExceeded ? 'border-2 border-red-500' : ''
    }`}>
      <div className="flex justify-between items-start">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        {isExceeded && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            <AlertTriangle className="w-3 h-3 mr-1" />
            Limit Exceeded
          </span>
        )}
      </div>
      
      <div className="mt-2">
        <div className="flex items-baseline">
          <p className={`text-2xl font-semibold ${
            isExceeded ? 'text-red-600' : 'text-gray-900'
          }`}>{mainStat}</p>
          <p className="ml-2 text-sm text-gray-500">{subStat}</p>
        </div>
        
        {showProgress && (
          <div className="mt-3">
            <div className="relative h-2 bg-gray-100 rounded">
              <div 
                className={`absolute left-0 top-0 h-2 rounded ${
                  isExceeded ? 'bg-red-500' : 'bg-blue-600'
                }`}
                style={{ width: `${percentage}%` }}
              />
            </div>
            <div className="mt-1 flex justify-between items-center text-xs">
              <span className="text-gray-500">
                {current} of {limit === Infinity ? 'Unlimited' : limit} used
              </span>
              {isExceeded && (
                <span className="text-red-600">
                  +{current - limit} over limit
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Usage Warning Banner
const UsageWarningBanner = ({ current, limit, planUpgradeLink }) => (
  <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
    <div className="flex items-start">
      <AlertTriangle className="h-5 w-5 text-red-500 mt-0.5" />
      <div className="ml-3">
        <h3 className="text-sm font-medium text-red-800">
          Monthly Email Limit Exceeded
        </h3>
        <div className="mt-2 text-sm text-red-700">
          <p>You have processed {current} emails this month, exceeding your plan limit of {limit} emails.</p>
          <p className="mt-1">Additional emails may be delayed or require upgrade to a higher tier.</p>
        </div>
        <div className="mt-3">
          <a
            href={planUpgradeLink}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200"
          >
            <TrendingUp className="h-4 w-4 mr-1.5" />
            Upgrade Plan
          </a>
        </div>
      </div>
    </div>
  </div>
);

export const EmailUsageStats = ({ usage, limits }) => {
  const isExceeded = usage.emails.thisMonth > limits.emailsPerMonth;
  
  return (
    <>
      {isExceeded && (
        <UsageWarningBanner 
          current={usage.emails.thisMonth}
          limit={limits.emailsPerMonth}
          planUpgradeLink="/account/billing"
        />
      )}
      <StatsCard
        title="Email Processing"
        mainStat={`${usage.emails.thisMonth}`}
        subStat={`of ${limits.emailsPerMonth} this month`}
        current={usage.emails.thisMonth}
        limit={limits.emailsPerMonth}
        showProgress={true}
        isExceeded={isExceeded}
      />
    </>
  );
};

// Enhanced Mask Item Component
const MaskItem = ({ mask, onToggle, onSelect, isSelected }) => {
  // Initialize rate limiting hook for this specific mask
  const { getRateLimits, formatTimeRemaining } = useRateLimit(mask.id);
  const rateLimits = getRateLimits();
  
  const getRateLimitWarning = () => {
    if (!rateLimits) return null;
    
    if (rateLimits.isLimited) {
      return (
        <div className="mt-2 text-sm text-red-600">
          Rate limit reached. {formatTimeRemaining(rateLimits.nextReset)}
        </div>
      );
    }
    
    if (rateLimits.hourly.remaining < 5) {
      return (
        <div className="mt-2 text-sm text-yellow-600">
          Warning: Only {rateLimits.hourly.remaining} emails remaining this hour
        </div>
      );
    }
    
    return null;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm relative">
      <div className="absolute top-4 right-4 flex items-center gap-4">
        <Switch
          checked={mask.active}
          onCheckedChange={() => onToggle(mask)}
        />
        <Checkbox
          checked={isSelected}
          onCheckedChange={() => onSelect(mask.id)}
        />
      </div>

      <div className="space-y-2">
        <div className="flex items-center gap-2">
          <span className="font-medium">{mask.maskEmail}</span>
          <span className={`px-2 py-1 text-xs font-medium rounded-full ${
            mask.active ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
          }`}>
            {mask.active ? 'Active' : 'Inactive'}
          </span>
        </div>
        
        <p className="text-sm text-gray-600">Forwards to: {mask.forwardTo}</p>
        <p className="text-sm text-gray-500">{mask.description}</p>
        
        {rateLimits && (
          <div className="mt-2 text-sm">
            <div className="flex items-center gap-4">
              <span className="text-gray-500">
                Hourly: {rateLimits.hourly.remaining}/{rateLimits.hourly.limit}
              </span>
              <span className="text-gray-500">
                Per minute: {rateLimits.burst.remaining}/{rateLimits.burst.limit}
              </span>
            </div>
            {getRateLimitWarning()}
          </div>
        )}
        
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
          <div>
            <div className="text-sm text-gray-500">Emails Received</div>
            <div className="font-medium">{mask.statistics?.totalReceived || 0}</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">Emails Forwarded</div>
            <div className="font-medium">{mask.statistics?.totalForwarded || 0}</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">Spam Blocked</div>
            <div className="font-medium">{mask.statistics?.spamBlocked || 0}</div>
          </div>
          <div>
            <div className="text-sm text-gray-500">Last Used</div>
            <div className="font-medium">
              {mask.statistics?.lastUsed ? new Date(mask.statistics.lastUsed).toLocaleString() : 'Never'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Enhanced Masks Section Component
function MasksSection({ masks = [], onCreateMask, onDeleteMask, showAlert, onRefresh }) {
  const [selectedMasks, setSelectedMasks] = useState(new Set());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleToggleActive = async (mask) => {
    try {
      await updateMaskAPI(mask.id, {
        ...mask,
        active: !mask.active
      });
      onRefresh();
      showAlert('Mask status updated successfully', 'success');
    } catch (error) {
      console.error('Error updating mask:', error);
      showAlert(error.message || 'Failed to update mask status', 'error');
    }
  };

  const handleSelect = (maskId) => {
    const newSelected = new Set(selectedMasks);
    if (newSelected.has(maskId)) {
      newSelected.delete(maskId);
    } else {
      newSelected.add(maskId);
    }
    setSelectedMasks(newSelected);
  };

  const handleBulkAction = async (action) => {
    if (action === 'delete' && !window.confirm(`Are you sure you want to delete ${selectedMasks.size} mask(s)?`)) {
      return;
    }

    setIsRefreshing(true);
    try {
      const results = await Promise.allSettled(
        Array.from(selectedMasks).map(maskId => {
          const mask = masks.find(m => m.id === maskId);
          
          if (action === 'delete') {
            return deleteMaskAPI(maskId).then(() => onDeleteMask(maskId));
          }

          return updateMaskAPI(maskId, {
            ...mask,
            active: action === 'activate'
          });
        })
      );

      const failures = results.filter(result => result.status === 'rejected');
      if (failures.length > 0) {
        showAlert(`Failed to ${action} ${failures.length} mask(s). Please try again.`, 'error');
      } else {
        showAlert(`Successfully ${action}d ${results.length} mask(s)`, 'success');
      }

      setSelectedMasks(new Set());
      onRefresh();
    } catch (error) {
      showAlert(`Failed to ${action} masks: ${error.message}`, 'error');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await onRefresh();
      showAlert('Data refreshed successfully', 'success');
    } catch (error) {
      showAlert('Failed to refresh data', 'error');
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <h3 className="text-xl font-semibold">Email Masks</h3>
          {selectedMasks.size > 0 && (
            <div className="flex gap-2 ml-4">
              <button
                onClick={() => handleBulkAction('activate')}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Activate ({selectedMasks.size})
              </button>
              <button
                onClick={() => handleBulkAction('deactivate')}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Deactivate ({selectedMasks.size})
              </button>
              <button
                onClick={() => handleBulkAction('delete')}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 flex items-center"
              >
                <TrashIcon className="w-4 h-4 mr-2" />
                Delete ({selectedMasks.size})
              </button>
            </div>
          )}
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center"
          >
            <RefreshCw className={`w-4 h-4 mr-2 ${isRefreshing ? 'animate-spin' : ''}`} />
            Refresh
          </button>
          <button
            onClick={() => setShowCreateModal(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            Create Mask
          </button>
        </div>
      </div>

      <div className="grid gap-4">
        {masks.length > 0 ? (
          masks.map((mask) => (
            <MaskItem
              key={mask.id}
              mask={mask}
              onToggle={handleToggleActive}
              onSelect={handleSelect}
              isSelected={selectedMasks.has(mask.id)}
            />
          ))
        ) : (
          <div className="text-center py-8 text-gray-500 bg-white rounded-lg shadow-sm">
            No masks created yet. Create your first mask to get started.
          </div>
        )}
      </div>

      <CreateMaskModal 
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={onCreateMask}
      />
    </div>
  );
}

// Overview Section Component
const OverviewSection = ({ userData }) => {
  if (!userData) {
    return <p>Loading account data...</p>;
  }

  // Email processing stats - using actual API data
  const emailStats = {
    totalReceived: userData?.usage?.emails?.total || 0,
    totalForwarded: userData?.usage?.emails?.forwarded || 0,
    spamBlocked: userData?.usage?.emails?.blocked || 0,
    thisMonth: userData?.usage?.emails?.thisMonth || 0

  };

  // Plan limits
  const monthlyLimit = userData?.limits?.emailsPerMonth || 100;
  const storageLimit = userData?.limits?.storageLimit || '1MB';
  const totalMaskLimit = userData?.limits?.maxMasks || 2;

  // Current usage from API
  const currentMonthEmails = userData?.usage?.emails?.thisMonth || 0;
  const activeMasks = userData?.usage?.masks?.active || 0;
  
  // Storage from API
  const storageUsed = userData?.usage?.storage || {};
  const storageBreakdown = storageUsed?.breakdown || {};

  return (
    <div className="space-y-6">
      {/* Main Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <StatsCard
          title="Email Processing"
          mainStat={`${currentMonthEmails}`}
          subStat={`of ${monthlyLimit} this month`}
          current={currentMonthEmails}
          limit={monthlyLimit}
          showProgress={true}
          isExceeded={currentMonthEmails > monthlyLimit} 
          progressColor="bg-blue-600"
        />
        
        <StatsCard
          title="Storage Used"
          mainStat={storageUsed.formatted || '0 B'}
          subStat={`of ${storageLimit}`}
          current={storageUsed.value || 0}
          limit={100}
          showProgress={true}
          progressColor="bg-purple-600"
        />

        <StatsCard
          title="Active Masks"
          mainStat={`${activeMasks}`}
          subStat={`of ${totalMaskLimit}`}
          current={activeMasks}
          limit={totalMaskLimit}
          showProgress={true}
          progressColor="bg-green-600"
        />
        {userData?.usage?.emails?.queued > 0 && (
        <QueueMonitor 
          queuedEmails={userData.queuedEmails}
          onRefresh={fetchAccountData}
          isLoading={loading}
        />
      )}
      </div>

      {/* Storage Breakdown */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <h3 className="text-lg font-semibold mb-4">Storage Breakdown</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className="text-center">
            <p className="text-sm text-gray-500">Emails</p>
            <p className="text-lg font-semibold">{storageBreakdown.emails || '0 B'}</p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-500">Masks</p>
            <p className="text-lg font-semibold">{storageBreakdown.masks || '0 B'}</p>
          </div>
          <div className="text-center">
            <p className="text-sm text-gray-500">Account</p>
            <p className="text-lg font-semibold">{storageBreakdown.account || '0 B'}</p>
          </div>
        </div>
      </div>

      {/* Email Statistics */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <h3 className="text-lg font-semibold mb-4">Email Statistics</h3>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <p className="text-sm text-gray-500">Total Received</p>
            <p className="text-2xl font-semibold">{emailStats.totalReceived}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Successfully Forwarded</p>
            <p className="text-2xl font-semibold">{emailStats.totalForwarded}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Spam Blocked</p>
            <p className="text-2xl font-semibold">{emailStats.spamBlocked}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">This Month</p>
            <p className="text-2xl font-semibold">{emailStats.thisMonth}</p>
          </div>
        </div>
      </div>

      {/* Account Details */}
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <h3 className="text-lg font-semibold mb-4">Account Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm text-gray-500">Email</p>
            <p className="font-medium">{userData.email}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Plan</p>
            <p className="font-medium capitalize">{userData.subscription?.tier || 'Free'}</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Account Created</p>
            <p className="font-medium">
              {new Date(userData.created).toLocaleDateString()}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Last Updated</p>
            <p className="font-medium">
              {userData.updated ? new Date(userData.updated).toLocaleDateString() : 'Never'}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">2FA Status</p>
            <p className="font-medium">
              {userData.twoFactor?.enabled ? 'Enabled' : 'Disabled'}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Key Status</p>
            <p className="font-medium capitalize">{userData.keyStatus}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Security Section Component
const SecuritySection = ({ userData, onSetupTwoFactor, showQRCode, twoFactorSetup }) => (
  <div className="space-y-6">
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <h3 className="text-xl font-semibold mb-4">Two-Factor Authentication</h3>
      {userData?.twoFactor?.enabled ? (
        <div className="space-y-4">
          <div className="flex items-center text-green-600">
            <span className="mr-2">✓</span>
            2FA is enabled
          </div>
          <button
            onClick={() => alert('Backup codes are currently unavailable.')}
            className="text-indigo-600 hover:underline"
          >
            View Backup Codes
          </button>
        </div>
      ) : showQRCode ? (
        <div className="space-y-4">
          <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(twoFactorSetup.uri)}`} alt="2FA QR Code" />
          <input
            type="text"
            placeholder="Enter verification code"
            className="w-full p-2 border rounded"
            onChange={(e) => twoFactorSetup.onVerify(e.target.value)}
          />
        </div>
      ) : (
        <button
          onClick={onSetupTwoFactor}
          className="bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
        >
          Enable 2FA
        </button>
      )}
    </div>
  </div>
);

// Billing Section Component
const BillingSection = ({ userData, isLoading, setIsLoading }) => {
  const handleUpgradePlan = async (priceId) => {
    setIsLoading(true);
    try {
      const { url } = await initiateCheckoutSession(priceId);
      if (url) {
        window.location.href = url;
      } else {
        throw new Error('Failed to initiate checkout session');
      }
    } catch (error) {
      console.error('Error upgrading plan:', error);
    } finally {
      setIsLoading(false);
    }
  };  

  const handleManagePlan = async () => {
    try {
      const response = await fetch('https://stripe.api.securimail.io/create-portal-session', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('securimail_token')}`,
        },
      });
      const { url } = await response.json();
      if (url) {
        window.location.href = url; // Redirect to Stripe Customer Portal
      } else {
        throw new Error('Failed to access billing portal');
      }
    } catch (error) {
      console.error('Error managing plan:', error);
    }
  };

  const isExceeded = userData?.usage?.emails?.thisMonth > userData?.limits?.emailsPerMonth;

  return (
    <div className="space-y-6">
      {isExceeded && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
            <div>
              <h3 className="text-red-800 font-medium">Plan Limit Exceeded</h3>
              <p className="text-red-700 mt-1">
                Upgrade your plan to continue sending emails without interruption.
              </p>
              <button
                onClick={() => handleUpgradePlan('price_1QQHDAC4G1ZEPm8Hl1EYUMms')} // Basic Plan
                className="mt-3 bg-red-100 text-red-700 px-4 py-2 rounded-md hover:bg-red-200 inline-flex items-center"
              >
                <TrendingUp className="h-4 w-4 mr-2" />
                Upgrade to Basic
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Additional Billing Section Code */}
    </div>
  );
};


// Main Account Page Component
const AccountPage = () => {
  const [activeSection, setActiveSection] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [masks, setMasks] = useState([]);
  const [twoFactorSetup, setTwoFactorSetup] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);
  const [alert, setAlert] = useState(null);
  const [getCachedData, setCachedData] = useLocalStorageWithTTL('securimail_dashboard_data', 300000);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const cachedData = getCachedData();
    if (cachedData) {
      setUserData(cachedData.accountData);
      setMasks(cachedData.masksData.masks || []);
      setLoading(false);
    } else {
      fetchAccountData();
    }
  }, []);

  const fetchAccountData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('securimail_token');
      const { account, masks } = await fetchAccountAndMasks(token);
      // Add usage check
      if (account.usage?.emails?.thisMonth > account.limits?.emailsPerMonth) {
        showAlert('You have exceeded your monthly email limit', 'warning');
      }
      setUserData(account);
      setMasks(masks.masks || []);
      setCachedData({ accountData: account, masksData: masks });
    } catch (error) {
      console.error('Error fetching data:', error);
      showAlert(error.message || 'Error fetching account data');
    } finally {
      setLoading(false);
    }
  };
  
  const showAlert = (message, type = 'error') => {
    setAlert({ message, type });
    setTimeout(() => setAlert(null), 5000);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const createMask = async (description, forwardTo) => {
    if (!description || !forwardTo) {
      showAlert('Description and forwarding email are required', 'error');
      return;
    }
    if (!validateEmail(forwardTo)) {
      showAlert('Invalid email format for forwardTo', 'error');
      return;
    }
  
    try {
      const token = localStorage.getItem('securimail_token');
      const newMask = await createMaskAPI(description, forwardTo);
      setMasks((prevMasks) => [...prevMasks, newMask]);
      fetchAccountData();
      showAlert('Mask created successfully', 'success');
    } catch (error) {
      console.error('Error creating mask:', error);
      showAlert(error.message || 'Error creating mask', 'error');
    }
  };

  const deleteMask = async (maskId) => {
    try {
      const token = localStorage.getItem('securimail_token');
      await deleteMaskAPI(token, maskId);
      setMasks((prevMasks) => prevMasks.filter((mask) => mask.id !== maskId));
      fetchAccountData();
      showAlert('Mask deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting mask:', error);
      showAlert(error.message || 'Error deleting mask');
    }
  };

  const onSetupTwoFactor = async () => {
    try {
      const response = await setupTwoFactorAPI();
      setTwoFactorSetup(response);
      setShowQRCode(true);
    } catch (error) {
      console.error('Error setting up 2FA:', error);
      showAlert(error.message || 'Error setting up 2FA');
    }
  };

  const verifyTwoFactor = async (code) => {
    try {
      await verifyTwoFactorAPI(code);
      fetchAccountData();
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      showAlert('Failed to verify 2FA code', 'error');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-indigo-600"></div>
          <p className="mt-4 text-gray-500">Loading your account...</p>
        </div>
      </div>
    );
  }

  const sections = [
    { id: 'overview', title: 'Overview', icon: Shield, component: <OverviewSection userData={userData} /> },
    { id: 'masks', title: 'Masks', icon: Mail, component: (
      <MasksSection 
        masks={masks} 
        onCreateMask={createMask} 
        onDeleteMask={deleteMask} 
        showAlert={showAlert}
        onRefresh={fetchAccountData}
      />
    )},
    { id: 'security', title: 'Security', icon: Lock, component: (
      <SecuritySection
        userData={userData}
        onSetupTwoFactor={onSetupTwoFactor}
        showQRCode={showQRCode}
        twoFactorSetup={{ ...twoFactorSetup, onVerify: verifyTwoFactor }}
      />
    )},
    { id: 'billing', title: 'Billing', icon: CreditCard, component: 
      <BillingSection 
        userData={userData}
        isLoading={isLoading} 
        setIsLoading={setIsLoading} 
      />
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert(null)}
        />
      )}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-64 space-y-2">
            {sections.map(({ id, title, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveSection(id)}
                className={`w-full flex items-center space-x-3 px-4 py-3 rounded-lg ${
                  activeSection === id ? 'bg-indigo-600 text-white' : 'bg-white hover:bg-gray-50'
                }`}
              >
                <Icon className="h-5 w-5" />
                <span>{title}</span>
              </button>
            ))}
          </div>

          <div className="flex-1">
            {sections.find((section) => section.id === activeSection)?.component}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;