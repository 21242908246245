import React from 'react';
import { JsonLd } from 'react-schemaorg';

const SEOSchema = ({ page, data = {} }) => {
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "SecuriMail",
    url: "https://getsecurimail.com",
    description: "Protect your inbox with disposable email masks. SecuriMail provides advanced email privacy and spam protection.",
    publisher: {
      "@type": "Organization",
      name: "SecuriMail",
      logo: {
        "@type": "ImageObject",
        url: "https://getsecurimail.com/logo.png"
      }
    }
  };

  const schemas = {
    home: {
      "@type": "WebSite",
      ...baseSchema,
      potentialAction: {
        "@type": "SearchAction",
        target: "https://getsecurimail.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    },

    features: {
      "@type": "Product",
      ...baseSchema,
      category: "Email Security Software",
      offers: {
        "@type": "AggregateOffer",
        lowPrice: "0",
        highPrice: "19.99",
        priceCurrency: "USD",
        offerCount: "4"
      }
    },

    pricing: {
      "@type": "Product",
      ...baseSchema,
      offers: {
        "@type": "AggregateOffer",
        lowPrice: "0",
        highPrice: "19.99",
        priceCurrency: "USD",
        offerCount: "4"
      }
    },

    about: {
      "@type": "AboutPage",
      ...baseSchema
    },

    faq: {
      "@type": "FAQPage",
      ...baseSchema,
      mainEntity: data.faqs?.map(faq => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer
        }
      })) || []
    },

    blog: {
      "@type": "Blog",
      ...baseSchema,
      blogPost: data.posts?.map(post => ({
        "@type": "BlogPosting",
        headline: post.title,
        datePublished: post.publishedDate,
        author: {
          "@type": "Person",
          name: post.author
        },
        description: post.excerpt
      })) || []
    },

    blogPost: {
      "@type": "BlogPosting",
      ...baseSchema,
      headline: data.title,
      datePublished: data.publishedDate,
      author: {
        "@type": "Person",
        name: data.author
      },
      description: data.excerpt
    },

    privacyPolicy: {
      "@type": "WebPage",
      ...baseSchema,
      specialty: "Privacy Policy"
    }
  };

  const campaignSchema = {
    "@type": "WebPage",
    ...baseSchema,
    description: `SecuriMail ${data.campaign} - ${data.description}`,
    mainEntity: {
      "@type": "Product",
      name: "SecuriMail Email Masking",
      description: data.description
    }
  };

  // Select schema based on page type
  const selectedSchema = schemas[page] || 
    (page.startsWith('campaign-') ? campaignSchema : baseSchema);

  return <JsonLd item={selectedSchema} />;
};

export default SEOSchema;