import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Search } from 'lucide-react';
import SEOSchema from './SEOSchema';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const faqCategories = [
    {
      category: "Getting Started",
      questions: [
        {
          q: "What is SecuriMail?",
          a: "SecuriMail is a privacy-focused email masking service that creates unique, disposable email addresses (masks) that forward to your real inbox. This helps protect your privacy and manage unwanted emails."
        },
        {
          q: "How do email masks work?",
          a: "When you create a mask, it generates a unique email address that forwards messages to your real email. You can create different masks for different services, and if one starts receiving spam, you can disable it without affecting your real email."
        },
        {
          q: "Is SecuriMail free to use?",
          a: "SecuriMail offers a free tier with basic features including 2 active masks and 100 emails per month. Paid plans start at $4.99/month and offer more masks, increased email limits, and advanced features."
        }
      ]
    },
    {
      category: "Privacy & Security",
      questions: [
        {
          q: "How secure is SecuriMail?",
          a: "SecuriMail uses industry-standard encryption for all data transmission and storage. We never read your emails, and our infrastructure is regularly audited by independent security firms."
        },
        {
          q: "Can someone trace my real email through a mask?",
          a: "No. Your real email address is never exposed to senders. Even if a mask is compromised, your actual email remains private and secure."
        },
        {
          q: "What happens if I delete a mask?",
          a: "When you delete a mask, it immediately stops forwarding emails to your inbox. Any future emails sent to that mask will be rejected."
        }
      ]
    },
    {
      category: "Account & Billing",
      questions: [
        {
          q: "How do I upgrade my plan?",
          a: "You can upgrade your plan anytime from your account dashboard. We offer monthly and annual billing options with significant savings on annual plans."
        },
        {
          q: "Can I cancel my subscription?",
          a: "Yes, you can cancel your subscription at any time. Your masks will continue working until the end of your billing period."
        },
        {
          q: "What payment methods do you accept?",
          a: "We accept all major credit cards, PayPal, and select cryptocurrency payments for premium plans."
        }
      ]
    },
    {
      category: "Technical Support",
      questions: [
        {
          q: "What if emails aren't being forwarded?",
          a: "First, check if the mask is still active in your dashboard. If issues persist, contact our support team with the mask address and we'll investigate immediately."
        },
        {
          q: "Can I reply to emails through my mask?",
          a: "Yes! Replies automatically go through your mask, keeping your real email private. This works with most email clients and services."
        },
        {
          q: "How do I install the browser extension?",
          a: "Visit our website and click 'Install Extension' or find us directly in your browser's extension store. We support Chrome, Firefox, Safari, and Edge."
        }
      ]
    }
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const filteredFAQs = faqCategories.map(category => ({
    ...category,
    questions: category.questions.filter(
      qa => 
        qa.q.toLowerCase().includes(searchQuery.toLowerCase()) ||
        qa.a.toLowerCase().includes(searchQuery.toLowerCase())
    )
  })).filter(category => category.questions.length > 0);

  return (
    <>
    <SEOSchema page="faq" />
    <div className="max-w-4xl mx-auto p-6">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4 text-indigo-900">Frequently Asked Questions</h2>
        <p className="text-gray-600 mb-8">
          Find answers to common questions about SecuriMail's features and services
        </p>
        
        {/* Search Bar */}
        <div className="relative max-w-xl mx-auto">
          <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search FAQ..."
            className="w-full pl-12 pr-4 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-shadow"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      {/* Display message if no results found */}
      {filteredFAQs.length === 0 && (
        <div className="bg-indigo-50 border border-indigo-100 rounded-lg p-4 text-center mb-6">
          <p className="text-indigo-800">
            No matching questions found. Try adjusting your search terms or contact our support team for help.
          </p>
        </div>
      )}

      {/* FAQ Categories */}
      <div className="space-y-8">
        {filteredFAQs.map((category, categoryIndex) => (
          <div key={categoryIndex} className="bg-white rounded-lg shadow-sm border border-indigo-100 p-6">
            <h3 className="text-xl font-semibold mb-4 text-indigo-800">{category.category}</h3>
            <div className="space-y-4">
              {category.questions.map((qa, questionIndex) => {
                const index = `${categoryIndex}-${questionIndex}`;
                return (
                  <div key={questionIndex} className="border-b border-indigo-50 last:border-0 pb-4 last:pb-0">
                    <button
                      className="w-full text-left flex justify-between items-center py-2 hover:text-indigo-700 transition-colors"
                      onClick={() => handleToggle(index)}
                    >
                      <span className="font-medium pr-8">{qa.q}</span>
                      {openIndex === index ? (
                        <ChevronUp className="h-5 w-5 text-indigo-500" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                    {openIndex === index && (
                      <div className="mt-2 text-gray-600 pl-4 border-l-2 border-indigo-200">
                        {qa.a}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      {/* Contact Support Section */}
      <div className="mt-12 text-center p-6 bg-indigo-50 rounded-lg border border-indigo-100">
        <h3 className="text-lg font-semibold mb-2 text-indigo-900">Still have questions?</h3>
        <p className="text-gray-600 mb-4">
          Our support team is here to help you with any questions or concerns.
        </p>
        <button className="bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 transition-colors">
          Contact Support
        </button>
      </div>
    </div>
    </>
  );
};

export default FAQ;