import React from 'react';
import { Shield, Mail, Lock, Fingerprint, ArrowRight, Check } from 'lucide-react';
import SEOSchema from './SEOSchema';

const ProductOverview = () => {
  const features = [
    {
      title: "Stop Spam at the Source",
      description: "Create unique email masks for every service you use. If one starts sending spam, simply disable that mask without affecting your real inbox.",
      icon: Mail
    },
    {
      title: "Protect Your Privacy",
      description: "Keep your real email private. Each mask forwards to your real address while hiding it from companies, marketers, and potential bad actors.",
      icon: Lock
    },
    {
      title: "Control Your Identity",
      description: "Know exactly who shared your email. Each mask shows you which service is sending you mail, making data breaches instantly traceable.",
      icon: Fingerprint
    }
  ];

  const useCases = [
    {
      scenario: "Online Shopping",
      benefit: "Create unique masks for each store. If they leak your data, you'll know exactly who did it."
    },
    {
      scenario: "Job Applications",
      benefit: "Track which recruiters are sharing your contact info and maintain professional privacy."
    },
    {
      scenario: "Social Media",
      benefit: "Protect yourself from data mining while staying connected with what matters."
    }
  ];

  return (
    <>
    <SEOSchema page="overview" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="flex justify-center mb-4">
            <Shield className="h-16 w-16 text-indigo-600" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Your Privacy Shield for the Digital Age
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            SecuriMail creates disposable email masks that protect your real email address while giving you complete control over who can contact you.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="p-6 rounded-lg border border-gray-200 hover:border-indigo-500 transition-colors">
              <feature.icon className="h-8 w-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* How It Works */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">1</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Generate a Mask</h3>
              <p className="text-gray-600">Create an email mask with one click for any service or website</p>
            </div>
            <ArrowRight className="hidden md:block h-6 w-6 text-gray-400" />
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">2</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Use Your Mask</h3>
              <p className="text-gray-600">Share your masked email instead of your real address</p>
            </div>
            <ArrowRight className="hidden md:block h-6 w-6 text-gray-400" />
            <div className="flex-1 text-center px-4">
              <div className="bg-indigo-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-xl font-bold text-indigo-600">3</span>
              </div>
              <h3 className="text-lg font-semibold mb-2">Stay Protected</h3>
              <p className="text-gray-600">Emails forward to your inbox while your real address stays private</p>
            </div>
          </div>
        </div>

        {/* Real World Examples */}
        <div className="bg-gray-50 rounded-xl p-8">
          <h2 className="text-3xl font-bold text-center mb-8">Real World Protection</h2>
          <div className="grid md:grid-cols-3 gap-6">
            {useCases.map((useCase, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-lg font-semibold mb-3">{useCase.scenario}</h3>
                <p className="text-gray-600 flex items-start">
                  <Check className="h-5 w-5 text-indigo-500 mt-1 mr-2 flex-shrink-0" />
                  {useCase.benefit}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ProductOverview;