import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SEOSchema from './SEOSchema';

const testimonials = [
  {
    name: "Sophia Bennett",
    role: "Digital Privacy Enthusiast",
    testimonial:
      "SecuriMail gives me peace of mind knowing my inbox is protected. The email masking feature is brilliant for maintaining privacy!",
    image: "/images/sophia.jpg",
  },
  {
    name: "Ethan Harper",
    role: "Freelance Web Developer",
    testimonial:
      "I can’t imagine managing client communications without SecuriMail. It’s a must-have for anyone who values their time and security.",
    image: "/images/ethan.jpg",
  },
  {
    name: "Olivia Chen",
    role: "Tech Entrepreneur",
    testimonial:
      "SecuriMail is like a personal security guard for my inbox. It’s incredibly easy to use and highly effective.",
    image: "/images/olivia.jpg",
  },
  {
    name: "Mason Carter",
    role: "Cybersecurity Consultant",
    testimonial:
      "As someone who helps businesses with security, I highly recommend SecuriMail. It’s a vital tool for protecting your personal data.",
    image: "/images/mason.jpg",
  },
  {
    name: "Ava Patel",
    role: "Online Shopper Extraordinaire",
    testimonial:
      "No more spam from online stores! I love how I can disable email masks whenever I want. SecuriMail is a game-changer.",
    image: "/images/ava.jpg",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <>
    <SEOSchema page="testimonials" />
    <div className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Intro Section */}
        <section className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-gray-900">What Our Users Say</h1>
          <p className="mt-4 text-lg text-gray-600">
            Hear directly from our users about how SecuriMail has enhanced their email privacy, productivity, and peace of mind.
          </p>
        </section>

        {/* Testimonials Section */}
        <section className="relative bg-white shadow-lg rounded-lg overflow-hidden max-w-3xl mx-auto p-8">
          <div className="flex justify-between items-center absolute top-1/4 -translate-y-1/4 w-full px-4">
            <button
              onClick={handlePrevious}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition"
            >
              <ChevronLeft className="w-6 h-6 text-gray-600" />
            </button>
            <button
              onClick={handleNext}
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition"
            >
              <ChevronRight className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <div className="text-center">
            <img
              src={testimonials[currentIndex].image}
              alt={testimonials[currentIndex].name}
              className="w-20 h-20 rounded-full mx-auto mb-4"
            />
            <blockquote className="text-lg italic text-gray-700">
              "{testimonials[currentIndex].testimonial}"
            </blockquote>
            <p className="text-sm font-medium text-gray-900 mt-4">
              {testimonials[currentIndex].name}
            </p>
            <p className="text-sm text-gray-500">{testimonials[currentIndex].role}</p>
          </div>
        </section>

        {/* Feature Expansion Section */}
        <section className="mt-12 text-center">
          <h2 className="text-3xl font-bold text-gray-900">Why Our Users Love SecuriMail</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Enhanced Privacy
              </h3>
              <p className="text-gray-600">
                Our users value the ability to mask their email address, keeping their personal information safe and secure.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Effortless Spam Control
              </h3>
              <p className="text-gray-600">
                Say goodbye to spam forever. SecuriMail filters out unwanted messages before they reach your inbox.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Customizable Experience
              </h3>
              <p className="text-gray-600">
                Each user has full control over their email masks, from creation to deletion, ensuring a tailored experience.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    </>
  );
};

export default Testimonials;
