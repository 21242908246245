// checkoutUtils.js
import { loadStripe } from '@stripe/stripe-js';

export const handleNewUserCheckout = async (setLoading) => {
  try {
    setLoading(true);
    
    const response = await fetch('https://stripe.api.securimail.io/create-new-user-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: 'price_1QQHAIC4G1ZEPm8H0YExIjtr' // Free plan ID
      }),
    });
    
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    
    const { sessionId } = await response.json();
    if (!sessionId) throw new Error('No session ID returned');
    
    const stripe = await loadStripe('pk_live_51QOiTRC4G1ZEPm8HZmGkvjV0IU8EHSDxCJhuNbC92t2EkISECb0AbDmjR2GjSzRlwNU6t9PQsQUZfVWW4JRImZlw001usKZC4e');
    await stripe.redirectToCheckout({ sessionId });
  } catch (error) {
    console.error('Checkout error:', error);
    alert('Unable to start free trial. Please try again later.');
  } finally {
    setLoading(false);
  }
};