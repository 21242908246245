// auth/api.js
export const makeAuthenticatedRequest = async (endpoint, options = {}) => {
  const token = localStorage.getItem('securimail_token');
  const response = await fetch(`https://api.securimail.io${endpoint}`, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });



  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Session expired. Please login again.');
    }
    if (response.status === 403) {
      throw new Error('Access denied. Please check your permissions.');
    }
    const error = await response.json();
    throw new Error(error.message || 'Request failed');
  }
  return response.json();
};

export const makeAuthenticatedRequestStripe = async (endpoint, options = {}) => {
  const token = localStorage.getItem('securimail_token');
  const response = await fetch(`https://stripe.api.securimail.io${endpoint}`, {
    ...options,
    headers: {
      ...options.headers,
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });



  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      throw new Error('Session expired. Please login again.');
    }
    if (response.status === 403) {
      throw new Error('Access denied. Please check your permissions.');
    }
    const error = await response.json();
    throw new Error(error.message || 'Request failed');
  }
  return response.json();
};

export const verify2FALogin = async (email, code, tempToken) => {
  const response = await fetch('https://api.securimail.io/login/verify2fa', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, code, tempToken })
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to verify 2FA');
  }
  const data = await response.json();

  // Store user_id and token in localStorage
  localStorage.setItem('user_id', data.user.id); // Store the user's ID
  localStorage.setItem('securimail_token', data.token); // Store the token

  return data;
};

// Account & Core
export const fetchAccountAndMasks = async () => {
  const [account, masks] = await Promise.all([
    makeAuthenticatedRequest('/account'),
    makeAuthenticatedRequest('/masks')
  ]);
  return { account, masks };
};

export const fetchAccountUsage = () => makeAuthenticatedRequest('/account/usage');

// Masks Management
export const createMaskAPI = (description, forwardTo) =>
  makeAuthenticatedRequest('/masks', {
    method: 'POST',
    body: JSON.stringify({ description, forwardTo })
  });

export const deleteMaskAPI = (maskId) =>
  makeAuthenticatedRequest(`/masks/${maskId}`, {
    method: 'DELETE'
  });

export const updateMaskAPI = (maskId, updates) =>
  makeAuthenticatedRequest(`/masks/${maskId}`, {
    method: 'PUT',
    body: JSON.stringify(updates)
  });

// Security Features
export const setupKeys = () => 
  makeAuthenticatedRequest('/keys/setup', {
    method: 'POST'
  });

export const fetchKeyStatus = () => makeAuthenticatedRequest('/keys/status');

export const setupTwoFactorAPI = () =>
  makeAuthenticatedRequest('/account/2fa/setup', {
    method: 'POST'
  });

export const verifyTwoFactorAPI = (code) =>
  makeAuthenticatedRequest('/account/2fa/verify', {
    method: 'POST',
    body: JSON.stringify({ code })
  });

// Billing & Subscription
export const fetchBillingPortal = () =>
  makeAuthenticatedRequest('/create-portal-session', {
    method: 'POST'
  });

export const fetchBillingHistory = () => 
  makeAuthenticatedRequest('/billing/history');

export const initiateCheckoutSession = async (priceId) => {
  const userId = localStorage.getItem('user_id');
  const userEmail = localStorage.getItem('user_email'); // Add this line to store email
  const token = localStorage.getItem('securimail_token');

  const response = await fetch('https://stripe.api.securimail.io/create-checkout-session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'X-User-Id': userId,
      'X-User-Email': userEmail, // Add email to headers
    },
    body: JSON.stringify({ 
      priceId,
      email: userEmail // Include email in the request body as well
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to create checkout session');
  }

  return await response.json();
};
