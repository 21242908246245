import { useState, useEffect } from 'react';

const parseFrontmatter = (content) => {
    try {
      // Split content into frontmatter and body
      const [_, frontmatter, ...bodyParts] = content.split('---');
      
      // Parse frontmatter into object
      const metadata = frontmatter.split('\n').reduce((acc, line) => {
        const [key, ...values] = line.split(':');
        if (key && values.length) {
          // Clean up the key and value
          const cleanKey = key.trim();
          const cleanValue = values.join(':').trim();
          if (cleanKey && cleanValue) {
            // Handle tags specially
            if (cleanKey === 'tags') {
              acc[cleanKey] = cleanValue.split('\n')
                .filter(tag => tag.trim().startsWith('-'))
                .map(tag => tag.trim().replace('-', '').trim());
            } else {
              acc[cleanKey] = cleanValue.replace(/^['"]|['"]$/g, '');
            }
          }
        }
        return acc;
      }, {});
  
      // Join the remaining content
      const bodyContent = bodyParts.join('---').trim();
  
      return {
        ...metadata,
        content: bodyContent
      };
    } catch (error) {
      console.error('Error parsing frontmatter:', error);
      return { content: content };
    }
  };

export const useBlogPosts = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          console.log('Attempting to load blog posts...');
          const postModules = import.meta.glob('../content/posts/*.md', { as: 'raw' });
          console.log('Available post modules:', postModules);
          
          const postEntries = await Promise.all(
            Object.entries(postModules).map(async ([path, resolver]) => {
              console.log('Processing path:', path);
              const content = await resolver();
              console.log('Content loaded:', content.substring(0, 100) + '...');
              const slug = path.split('/').pop().replace('.md', '');
              const parsed = parseFrontmatter(content);
              
              return {
                slug,
                entry: {
                  ...parsed,
                  publishedDate: parsed.publishedDate?.replace(/['"]/g, '') || '',
                }
              };
            })
          );
  
          console.log('Processed entries:', postEntries);
          
          const sortedPosts = postEntries.sort((a, b) => 
            new Date(b.entry.publishedDate) - new Date(a.entry.publishedDate)
          );
  
          setPosts(sortedPosts);
        } catch (err) {
          console.error('Detailed error:', err);
          setError('Failed to load blog posts');
        } finally {
          setLoading(false);
        }
      };
  
      fetchPosts();
    }, []);
  
    return { posts, loading, error };
  };

export const useBlogPost = (slug) => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postModules = import.meta.glob('/src/content/posts/*.md', { as: 'raw' });
        const matchingFile = Object.entries(postModules).find(([path]) => 
          path.includes(slug)
        );

        if (!matchingFile) {
          setError('Post not found');
          return;
        }

        const content = await matchingFile[1]();
        const parsed = parseFrontmatter(content);
        setPost({
          ...parsed,
          publishedDate: parsed.publishedDate?.replace(/['"]/g, '') || '',
        });
      } catch (err) {
        setError('Failed to load blog post');
        console.error('Error loading blog post:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  return { post, loading, error };
};