import React from 'react';
import { Link } from 'react-router-dom';
import { Shield } from 'lucide-react';
import { format } from 'date-fns';
import { useBlogPosts } from '../../utils/blog-content-loader';

const BlogIndex = () => {
  const { posts, loading, error } = useBlogPosts();

  console.log('BlogIndex rendering with posts:', posts); // Add logging

  if (loading) {
    return <div className="flex justify-center p-8 mt-16">
      <div className="animate-spin h-8 w-8 border-4 border-indigo-500 rounded-full border-t-transparent"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-500 p-8 text-center mt-16">{error}</div>;
  }

  if (!posts || posts.length === 0) {
    return <div className="text-gray-500 p-8 text-center mt-16">No blog posts found.</div>;
  }

  return (
    <div className="bg-white min-h-screen pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <Shield className="h-16 w-16 text-indigo-600 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">SecuriMail Blog</h1>
          <p className="text-xl text-gray-600">Security insights and updates</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post) => {
            console.log('Rendering post:', post); // Add logging
            return (
              <article key={post.slug} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
                <Link to={`/blog/${post.slug}`}>
                  <div className="p-6">
                    <div className="flex items-center space-x-4 text-sm text-gray-500 mb-2">
                      <time>{format(new Date(post.entry.publishedDate), 'MMM d, yyyy')}</time>
                      <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-full text-xs">
                        {post.entry.category}
                      </span>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">{post.entry.title}</h3>
                    <p className="text-gray-600">{post.entry.excerpt}</p>
                  </div>
                </Link>
              </article>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogIndex;