import React, { useState } from 'react';
import { Shield, Lock, Mail, Key, Bell, Users, Zap, Settings, Info } from 'lucide-react';
import SEOSchema from './SEOSchema';

const MarketingFeatures = () => {
  const [activeFeature, setActiveFeature] = useState('privacy');
  const [showTooltip, setShowTooltip] = useState('');

  const features = {
    privacy: {
      icon: Shield,
      title: "Advanced Privacy Protection",
      description: "SecuriMail puts you in control of your inbox with military-grade encryption and anonymous email masking.",
      benefits: [
        { text: "End-to-end encryption for all communications", note: null },
        { text: "Unlimited* anonymous email masks", note: "Available on Pro and Business plans" },
        { text: "Zero-knowledge architecture - we can't read your emails", note: null },
        { text: "Advanced data breach monitoring*", note: "Premium feature available on Pro and Business plans" }
      ]
    },
    security: {
      icon: Lock,
      title: "Personal Security",
      description: "Built with security-first architecture to protect your communications from threats.",
      benefits: [
        { text: "Advanced spam and phishing protection", note: null },
        { text: "Real-time threat analysis*", note: "Available on Pro and Business plans" },
        { text: "Custom security rules and filters*", note: "Advanced filters available on paid plans" },
        { text: "Two-factor authentication (2FA)*", note: "Required for Business plans, optional for others" }
      ]
    },
    masks: {
      icon: Mail,
      title: "Intelligent Email Masking",
      description: "Create disposable email addresses that forward to your real inbox.",
      benefits: [
        { text: "One-click mask generation (2-unlimited masks*)", note: "Free: 2 masks, Basic: 10 masks, Pro/Business: Unlimited" },
        { text: "Custom labels and organization*", note: "Available on Basic plans and above" },
        { text: "Advanced spam filtering*", note: "Enhanced filtering on paid plans" },
        { text: "Instant mask deactivation", note: null }
      ]
    },
    control: {
      icon: Settings,
      title: "Complete Control",
      description: "Powerful tools to manage your digital identity and email communications.",
      benefits: [
        { text: "Custom forwarding rules*", note: "Advanced rules available on Pro and Business plans" },
        { text: "Domain-based filtering*", note: "Available on paid plans" },
        { text: "Reply from masked addresses", note: null },
        { text: "Usage analytics and insights*", note: "Enhanced analytics on Pro and Business plans" }
      ]
    }
  };

  const Feature = ({ id, data }) => (
    <div className={`p-6 rounded-lg cursor-pointer transition-all duration-300 ${
        activeFeature === id 
          ? 'bg-indigo-50 border-indigo-200 shadow-sm' 
          : 'bg-white hover:bg-gray-50'
      }`}
      onClick={() => setActiveFeature(id)}
    >
      <div className="flex items-center space-x-4">
        <div className={`p-2 rounded-full ${
          activeFeature === id ? 'bg-indigo-100' : 'bg-gray-100'
        }`}>
          <data.icon className={`h-6 w-6 ${
            activeFeature === id ? 'text-indigo-600' : 'text-gray-600'
          }`} />
        </div>
        <div>
          <h3 className="text-lg font-semibold text-gray-900">{data.title}</h3>
          <p className="text-sm text-gray-600">{data.description}</p>
        </div>
      </div>
      
      {activeFeature === id && (
        <div className="mt-4 pl-12">
          <ul className="space-y-2">
            {data.benefits.map((benefit, index) => (
              <li key={index} className="flex items-start group">
                <div className="flex items-center text-gray-700">
                  <Zap className="h-4 w-4 text-indigo-500 mr-2 flex-shrink-0" />
                  {benefit.text}
                  {benefit.note && (
                    <div className="relative ml-2">
                      <Info 
                        className="h-4 w-4 text-gray-400 cursor-help"
                        onMouseEnter={() => setShowTooltip(`${id}-${index}`)}
                        onMouseLeave={() => setShowTooltip('')}
                      />
                      {showTooltip === `${id}-${index}` && (
                        <div className="absolute z-10 w-48 px-2 py-1 text-sm text-white bg-gray-900 rounded-md -right-2 transform translate-x-full">
                          {benefit.note}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  return (
    <>
    <SEOSchema page="marketingfeatures" />
    <div className="bg-white pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Your Personal Privacy Guardian
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Choose the perfect plan for your privacy needs, from basic protection to unlimited security features.
          </p>
        </div>

        <div className="grid gap-6 mb-16">
          {Object.entries(features).map(([id, data]) => (
            <Feature key={id} id={id} data={data} />
          ))}
        </div>

        <div className="text-center text-sm text-gray-500 mt-8">
          * Feature availability varies by plan. See pricing for details.
        </div>
      </div>
    </div>
    </>
  );
};

export default MarketingFeatures;