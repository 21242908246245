import React from 'react';
import { ShoppingBag, Briefcase, Share2, UserX, AlertTriangle, ShieldCheck, Heart, Zap } from 'lucide-react';
import SEOSchema from './SEOSchema';

const FeatureCases = () => {
  const useCases = [
    {
      icon: ShoppingBag,
      title: "Online Shopping",
      problem: "Tired of marketing spam after every purchase?",
      solution: "Create unique masks for each store. If they share your email or send too much spam, simply disable that mask."
    },
    {
      icon: Briefcase,
      title: "Job Hunting",
      problem: "Want to keep your job search private?",
      solution: "Use dedicated masks for job applications. Track which recruiters are sharing your contact info."
    },
    {
      icon: Share2,
      title: "Social Media",
      problem: "Worried about data breaches exposing your email?",
      solution: "Protect your primary email by using masks for social platforms. Change them instantly if compromised."
    },
    {
      icon: UserX,
      title: "Dating Sites",
      problem: "Need privacy while meeting new people?",
      solution: "Stay safe with disposable masks that can be disabled instantly if someone becomes problematic."
    }
  ];

  const privacyThreats = [
    {
      icon: AlertTriangle,
      title: "Email Tracking",
      description: "Marketers use hidden pixels to track when you open emails",
      solution: "Our masks automatically block tracking pixels*"
    },
    {
      icon: ShieldCheck,
      title: "Data Breaches",
      description: "Companies frequently leak customer data",
      solution: "Unique masks limit your exposure to breaches"
    },
    {
      icon: Heart,
      title: "Identity Theft",
      description: "Your email is the key to many accounts",
      solution: "Keep your real email private and secure"
    },
    {
      icon: Zap,
      title: "Spam Attacks",
      description: "One leak can lead to endless spam",
      solution: "Disable compromised masks instantly"
    }
  ];

  return (
    <>
    <SEOSchema page="about" />
    <div className="bg-gray-50 py-16 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Real-World Use Cases */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Privacy Where You Need It Most
          </h2>
          <p className="text-lg text-gray-600">
            See how SecuriMail protects your privacy in everyday situations
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-20">
          {useCases.map((useCase, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow">
              <div className="flex items-start space-x-4">
                <div className="bg-indigo-100 rounded-full p-3">
                  <useCase.icon className="h-6 w-6 text-indigo-600" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{useCase.title}</h3>
                  <p className="text-gray-500 mb-3">{useCase.problem}</p>
                  <p className="text-gray-700">{useCase.solution}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Common Privacy Threats */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Stay Protected from Common Threats
          </h2>
          <p className="text-lg text-gray-600">
            SecuriMail defends against modern privacy invasions
          </p>
        </div>

        <div className="grid md:grid-cols-4 gap-6">
          {privacyThreats.map((threat, index) => (
            <div key={index} className="bg-white rounded-lg p-6 text-center">
              <div className="flex justify-center mb-4">
                <div className="bg-red-100 rounded-full p-3">
                  <threat.icon className="h-6 w-6 text-red-600" />
                </div>
              </div>
              <h3 className="text-lg font-semibold text-gray-900 mb-2">{threat.title}</h3>
              <p className="text-sm text-gray-500 mb-3">{threat.description}</p>
              <p className="text-sm font-medium text-indigo-600">{threat.solution}</p>
            </div>
          ))}
        </div>

        {/* Browser Extension Promo */}
        <div className="bg-indigo-600 rounded-xl mt-20 p-8 text-white">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-6 md:mb-0">
              <h3 className="text-2xl font-bold mb-2">Get Protected in One Click</h3>
              <p className="text-indigo-100">
                Install our browser extension to create email masks instantly while browsing
              </p>
            </div>
            <div className="flex space-x-4">
              <button className="bg-white text-indigo-600 px-6 py-2 rounded-lg font-semibold hover:bg-indigo-50 transition-colors">
                Add to Chrome
              </button>
              <button className="bg-white text-indigo-600 px-6 py-2 rounded-lg font-semibold hover:bg-indigo-50 transition-colors">
                Add to Firefox
              </button>
            </div>
          </div>
        </div>

        <div className="text-center text-sm text-gray-500 mt-8">
          * Premium feature available on Pro and Business plans
        </div>
      </div>
    </div>
    </>
  );
};

export default FeatureCases;