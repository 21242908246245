import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';
import { useBlogPost } from '../../utils/blog-content-loader';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const BlogPost = () => {
  const { slug } = useParams();
  const { post, loading, error } = useBlogPost(slug);

  if (loading) {
    return <div className="flex justify-center p-8 mt-16">
      <div className="animate-spin h-8 w-8 border-4 border-indigo-500 rounded-full border-t-transparent"></div>
    </div>;
  }

  if (!post) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12 text-center mt-16">
        <h1 className="text-2xl font-bold text-gray-900">Post not found</h1>
        <Link to="/blog" className="text-indigo-600 hover:text-indigo-800 mt-4 inline-flex items-center">
          <ArrowLeft className="h-4 w-4 mr-2" />Back to Blog
        </Link>
      </div>
    );
  }

  // Custom components for ReactMarkdown
  const components = {
    // Handle security alerts
    securityAlert: ({ severity, children }) => (
      <div className={`my-4 p-4 rounded-lg ${
        severity === 'high' ? 'bg-red-50 text-red-700 border-l-4 border-red-500' :
        severity === 'medium' ? 'bg-yellow-50 text-yellow-700 border-l-4 border-yellow-500' :
        'bg-blue-50 text-blue-700 border-l-4 border-blue-500'
      }`}>
        {children}
      </div>
    ),
    // Handle code examples
    codeExample: ({ language, children }) => (
      <div className="my-4">
        <div className="bg-gray-800 text-white p-4 rounded-lg">
          <pre className="language-{language}">
            <code>{children}</code>
          </pre>
        </div>
      </div>
    ),
    // Style headings
    h2: ({ children }) => (
      <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">{children}</h2>
    ),
    // Style paragraphs
    p: ({ children }) => (
      <p className="text-gray-600 mb-4 leading-relaxed">{children}</p>
    ),
    // Style lists
    ul: ({ children }) => (
      <ul className="list-disc list-inside space-y-2 mb-4 text-gray-600">{children}</ul>
    ),
    ol: ({ children }) => (
      <ol className="list-decimal list-inside space-y-2 mb-4 text-gray-600">{children}</ol>
    ),
    // Style links
    a: ({ href, children }) => (
      <a href={href} className="text-indigo-600 hover:text-indigo-800 underline">{children}</a>
    ),
    // Style bold text
    strong: ({ children }) => (
      <strong className="font-semibold">{children}</strong>
    )
  };

  return (
    <article className="bg-white min-h-screen pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link to="/blog" className="text-indigo-600 hover:text-indigo-800 inline-flex items-center mb-8">
          <ArrowLeft className="h-4 w-4 mr-2" />Back to Blog
        </Link>

        <header className="text-center mb-12">
          <div className="flex items-center justify-center space-x-4 text-sm text-gray-500 mb-4">
            <time>{format(new Date(post.publishedDate), 'MMMM d, yyyy')}</time>
            <span>•</span>
            <span className="bg-indigo-100 text-indigo-800 px-3 py-1 rounded-full">{post.category}</span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.title}</h1>
          {post.authorRole && (
            <p className="text-gray-600">
              By {post.author} • {post.authorRole}
            </p>
          )}
        </header>

        <div className="prose prose-indigo max-w-none">
          <ReactMarkdown 
            components={components}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {post.content}
          </ReactMarkdown>
        </div>

        {post.tags && (
          <div className="mt-8 pt-8 border-t">
            <div className="flex flex-wrap gap-2">
              {post.tags.map((tag, index) => (
                <span key={index} className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm">{tag}</span>
              ))}
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

export default BlogPost;